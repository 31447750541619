import React, {useState} from "react";
import {
    Navbar,
    NavbarBrand,
    InputGroupText,
    InputGroupAddon,
    Input,
    InputGroup,
    Form,
    Container,
    Row,
    Col,
    Card,
    CardText,
    CardBody,
    CardTitle,
    CardSubtitle,
    Button
} from "reactstrap";

const App = () => {
    const [salePrice, setSalePrice] = useState("");
    const [brokerTax, setBrokerTax] = useState("");
    const [localTax, setLocalTax] = useState("");
    const [result, setResult] = useState({});

    const calculate = (e) => {
        e.preventDefault();
        const brokerFee = (salePrice * (brokerTax / 100));
        const cleanIncome = (salePrice - brokerFee);
        let taxedIncome;
        if (cleanIncome < 0) {
            taxedIncome = 0;
        } else {
            taxedIncome = (cleanIncome - (cleanIncome * (localTax / 100)));
        }


        setResult({
            salePrice: salePrice,
            brokerFee: brokerFee,
            cleanIncome: cleanIncome,
            tax: cleanIncome * (localTax / 100),
            taxedCleanIncome: taxedIncome
        });
    }
    return <>
        <div>
            <Navbar color="light" light expand="md">
                <NavbarBrand href="/">corner-valley</NavbarBrand>
            </Navbar>
            <Container>
                <Row>
                    <Col>
                        <Form onSubmit={(e) => calculate(e)}>
                            <InputGroup size="lg">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>$</InputGroupText>
                                </InputGroupAddon>
                                <Input required value={salePrice} onChange={(e) => {
                                    setResult({})
                                    const numberToSet = parseInt(e.target.value, 10);
                                    if (!isNaN(numberToSet))
                                        setSalePrice(numberToSet)
                                }} placeholder="Domain sale price"/>
                            </InputGroup>
                            <InputGroup size="lg">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>%</InputGroupText>
                                </InputGroupAddon>
                                <Input required value={brokerTax} onChange={(e) => {
                                    setResult({})
                                    const numberToSet = parseInt(e.target.value, 10);
                                    if (!isNaN(numberToSet))
                                        setBrokerTax(numberToSet)
                                }} placeholder="Broker tax fee in percentages e.g. 15%"/>
                            </InputGroup>
                            <InputGroup size="lg">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>%</InputGroupText>
                                </InputGroupAddon>
                                <Input required value={localTax} onChange={(e) => {
                                    setResult({})
                                    const numberToSet = parseInt(e.target.value, 10);
                                    if (!isNaN(numberToSet))
                                        setLocalTax(numberToSet)
                                }} placeholder="Local Tax fee for clean income, e.g. 19%"/>
                            </InputGroup>
                            <Button type={"submit"}>Calculate</Button>
                        </Form>
                    </Col>
                </Row>

                {result && result.salePrice && <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle>Results</CardTitle>
                                <CardSubtitle>Shows information about the subtotal and intermediate
                                    prices</CardSubtitle>
                                <CardText>
                                    <p style={{marginBottom: 0}}>Domain sale
                                        price: <strong>{new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: 'USD'
                                        }).format(result.salePrice)}</strong></p>
                                        <p style={{marginBottom: 0}}>Broker Fees (<strong style={{color: "red"}}>{brokerTax + "%"}</strong>): <strong>{new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: 'USD'
                                        }).format(result.brokerFee)}</strong></p>
                                        <p style={{marginBottom: 0}}>Clean Income Pre-tax/Post Broker-fee: <strong>{new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: 'USD'
                                        }).format(result.cleanIncome)}</strong></p>
                                    <p style={{marginBottom: 0}}>Taxes (<strong style={{color: "red"}}>{localTax + "%"}</strong>): <strong>{new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD'
                                    }).format(result.tax)}</strong></p>
                                        <p style={{marginBottom: 0}}>Clean Income After-tax: <strong>{new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: 'USD'
                                        }).format(result.taxedCleanIncome)}</strong></p>
                                    <a target={"_blank"} href={"https://www.google.com/search?q=" + encodeURIComponent(new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD'
                                    }).format(result.taxedCleanIncome) + " in EUR")}>USD->EUR</a>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>}
            </Container>
        </div>
    </>;
}

export default App;
